import React, {useMemo} from 'react';
import {FC} from 'react';
import {ReactComponent as ArrowRoundSvg} from '@assets/svgs/arrow-round.svg';
import {ReactComponent as PopupCloseSvg} from '@assets/svgs/popup-close.svg';
import {ReactComponent as CorrectSvg} from '@assets/svgs/correct.svg';

import {Popup} from 'antd-mobile';
import {useBoolean} from 'ahooks';
import {useTranslation} from 'react-i18next';

import styles from './picker.module.scss';
export interface PickerOptionsItem {
  label: string;
  value: string;
}

export interface PickerProps {
  className?: string;
  name: string;
  options?: PickerOptionsItem[];
  value: string;
  onChange: (value: string) => void;
}

const Picker: FC<PickerProps> = ({
  name,
  value,
  options = [],
  className = '',
  onChange,
}) => {
  const {t} = useTranslation();
  const allOptions = useMemo(() => {
    const nulStrItem = options.find(item => item.value === '');
    return [
      ...(nulStrItem
        ? []
        : [
            {
              label: t('label.allUpp'),
              value: '',
            },
          ]),
      ...options,
    ];
  }, [options, t]);
  const valueName = useMemo(() => {
    const option = options.find(item => item.value === value);
    return option?.label || name;
  }, [name, options, value]);
  const [visible, {setFalse, setTrue}] = useBoolean(false);
  const handleSelect = () => {
    setTrue();
  };
  return (
    <div
      className={`flex flex-row items-center justify-between h-8 px-2 rounded border-px border-line bg-button-s ${className}`}
      onClick={handleSelect}>
      <span className={`text-t2 text-xs ${styles['pick-select-text']}`}>
        {valueName}
      </span>
      <ArrowRoundSvg className="text-t4 w-3 h-3" />
      <Popup
        visible={visible}
        onMaskClick={setFalse}
        bodyClassName="max-h-[18.375rem] bg-card overflow-hidden flex flex-col rounded-t-lg">
        <div className="p-5 flex flex-row items-center justify-between">
          <span className="text-t1 text-sm font-bold">{name}</span>
          <div className="size-6" onClick={setFalse}>
            <PopupCloseSvg className="text-t3 size-6" />
          </div>
        </div>
        <div className="flex flex-col flex-1 overflow-auto px-5 mb-2">
          {allOptions.map(item => (
            <div
              key={item.value}
              className={`flex flex-row h-12 p-3 justify-between items-center ${
                item.value === value ? 'bg-button-s' : ''
              }`}
              onClick={() => {
                onChange(item.value);
                setFalse();
              }}>
              <span className="text-t2 text-xs">{item.label}</span>
              {item.value === value && (
                <CorrectSvg className="text-c1 size-3" />
              )}
            </div>
          ))}
        </div>
      </Popup>
    </div>
  );
};

export default Picker;
