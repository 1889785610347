import React, {
  forwardRef,
  ReactNode,
  RefAttributes,
  useMemo,
  useRef,
  useState,
} from 'react';
import {Button, Image, Grid as AGrid} from 'antd-mobile';
import {useTranslation} from 'react-i18next';
import {ReactComponent as ArrowSvg} from '@/assets/svgs/arrow.svg';
import {useScreenSize} from '@/utils';
import styles from './category.module.scss';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import {Grid} from 'swiper/modules';
import {Swiper as SwiperType} from 'swiper/types';

export interface CategoryProp<T> {
  icon?: ReactNode;
  title?: string;
  headerDesc?: ReactNode;
  className?: string;
  footer?: ReactNode;
  // 分类内部的列表
  list: T[];
  // 如果写入total表示这个分类会分页
  total?: number;
  // 进行分页时排多少行，默认1（不分页该属性无效）
  row?: number;
  // 如果进行分页，则表示一页默认排多少列；否则就是排多少列，默认1
  col?: number;
  slidesPerView?: number | 'auto';
  renderContentHeader?: React.ReactElement;
  renderItem?: (item: T, index?: number) => React.ReactElement;
  // 当不使用分页时，每个item占用行数
  itemSpan?: (item: T) => number;
  onAll?: () => void;
  // 使用padding内容的节点类型，默认content
  contentPaddingPosition?: 'content' | 'slider' | 'no-padding';
}
enum HeaderAction {
  all = 0,
  left = 1,
  right = 2,
}
interface HeaderProp {
  icon?: ReactNode;
  title?: string;
  rightItem?: React.ReactElement;
  desc?: ReactNode;
}
interface ActionBarProp {
  total: number;
  disabledLeft?: boolean;
  disabledRight?: boolean;
  onChange?: (idx: HeaderAction) => void;
}

export function ActionBar({
  total,
  disabledLeft,
  disabledRight,
  onChange,
}: ActionBarProp) {
  const {t} = useTranslation();
  return (
    <div className="flex flex-row gap-1 items-center action-bar">
      <Button
        className="category-btn px-2"
        onClick={() => onChange?.(HeaderAction.all)}>
        <span className="text-t1">{t('label.allUpp')}</span>
        <span className="text-c1">{total}</span>
      </Button>
      <Button
        className="category-btn w-6 text-t1"
        disabled={disabledLeft}
        onClick={() => onChange?.(HeaderAction.left)}>
        <ArrowSvg />
      </Button>
      <Button
        className="category-btn w-6 rotate-180 text-t1"
        disabled={disabledRight}
        onClick={() => onChange?.(HeaderAction.right)}>
        <ArrowSvg />
      </Button>
    </div>
  );
}

export function Header(props: HeaderProp) {
  return props.icon || props.title ? (
    <div
      className={`flex flex-col py-2 px-3 gap-2 ${styles['category-header']}`}>
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-2">
          {props.icon ? (
            typeof props.icon === 'string' ? (
              <Image src={props.icon} width={'auto'} height={'1.5rem'} />
            ) : (
              props.icon
            )
          ) : null}
          {!!props.title && (
            <span className="font-bold text-sm text-t1">{props.title}</span>
          )}
        </div>
        {props.rightItem}
      </div>
      {!!props.desc && <div>{props.desc}</div>}
    </div>
  ) : (
    <></>
  );
}

function Category<T>(
  {
    icon,
    title,
    total,
    list,
    row = 1,
    col = 1,
    headerDesc,
    footer,
    renderItem,
    onAll,
    className,
    contentPaddingPosition = 'content',
    slidesPerView = 'auto',
    itemSpan = _ => 1,
    renderContentHeader,
  }: CategoryProp<T>,
  ref: React.LegacyRef<HTMLDivElement>,
) {
  const [index, setIndex] = useState(0);
  const swiperRef = useRef<SwiperType>();
  const {calcActualSize} = useScreenSize();
  const realTotal = useMemo(() => {
    return list.length || 0;
  }, [list]);
  const onChange = (idx: HeaderAction) => {
    switch (idx) {
      case HeaderAction.all:
        onAll?.();
        break;
      case HeaderAction.left:
        swiperRef.current?.slideTo(index - col);

        break;
      case HeaderAction.right:
        swiperRef.current?.slideTo(index + col);
        break;
    }
  };
  const actualRow = useMemo(() => {
    if (total != null && realTotal <= row * col) {
      return 1;
    }
    return row;
  }, [total, realTotal, row, col]);

  return (
    <div
      ref={ref}
      className={`flex flex-col gap-2 ${styles.category} ${className}`}>
      <Header
        icon={icon}
        title={title}
        rightItem={
          total != null ? (
            <ActionBar
              total={total}
              disabledLeft={index <= 0}
              disabledRight={(index + col) * actualRow >= realTotal}
              onChange={onChange}
            />
          ) : undefined
        }
        desc={headerDesc}
      />
      <div
        className={`flex flex-col gap-3 ${
          contentPaddingPosition === 'content' ? 'px-3' : ''
        }`}>
        {renderContentHeader}
        {total != null && !!renderItem && (
          <Swiper
            slidesPerView={slidesPerView}
            spaceBetween={calcActualSize(8)}
            className={`swiper-category ${
              contentPaddingPosition === 'slider' ? 'px-3' : ''
            }`}
            modules={[Grid]}
            style={{width: '100%'}}
            onSwiper={(swiper: SwiperType) => (swiperRef.current = swiper)}
            grid={{
              rows: actualRow,
            }}
            onSlideChange={(swiper: SwiperType) => {
              setIndex(swiper.activeIndex);
            }}
            tabIndex={index}>
            {list.map((item, _index) => (
              <SwiperSlide key={_index}>{renderItem(item, _index)}</SwiperSlide>
            ))}
          </Swiper>
          // <Swiper
          //   indicator={() => null}
          //   slideSize={sliderSize}
          //   stuckAtBoundary={pairedList.length * (sliderSize || 100) > 100}
          //   onIndexChange={setIndex}
          //   className={contentPaddingPosition === 'slider' ? 'px-3' : ''}
          //   ref={swiperRef}>
          //   {pairedList.map((pairItems, pairsIndex) => (
          //     <Swiper.Item key={pairsIndex}>
          //       <Grid
          //         columns={actualCol}
          //         className={`grid-area ${
          //           sliderSize && sliderSize < 100 ? 'grid-not-full' : ''
          //         }`}>
          //         {pairItems.map((item, _index) => (
          //           <Grid.Item key={_index}>
          //             {renderItem(item, _index)}
          //           </Grid.Item>
          //         ))}
          //       </Grid>
          //     </Swiper.Item>
          //   ))}
          // </Swiper>
        )}
        {total == null && !!renderItem && (
          <AGrid columns={col} className="grid-area">
            {list?.map((item, _index) => (
              <AGrid.Item key={_index} span={itemSpan(item)}>
                {renderItem(item, _index)}
              </AGrid.Item>
            ))}
          </AGrid>
        )}
      </div>

      {footer || null}
    </div>
  );
}

type RefCategory<T = any> = (
  props: CategoryProp<T> & RefAttributes<HTMLDivElement>,
) => React.JSX.Element;
export default forwardRef(Category) as unknown as RefCategory;
