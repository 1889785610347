import {NoMoreData} from '@/components/basic/default-page';
import {inApp} from '@/utils/interface';
import {Image} from 'antd-mobile';
import React from 'react';
import {useTranslation} from 'react-i18next';

const Footer = () => {
  const {t} = useTranslation();
  return !inApp ? (
    <div className="bg-card py-6 px-3 gap-4 flex flex-col">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col gap-4">
          <Image
            src={require('@assets/imgs/footer/bottom-logo.webp')}
            width="10rem"
            height="1.4375rem"
          />
          <Image
            src={require('@assets/imgs/footer/not18.webp')}
            width="1.5rem"
            height="1.5rem"
          />
        </div>
        <div className="flex flex-col w-[8.375rem]">
          <Image
            src={require('@assets/imgs/footer/android.webp')}
            width="8rem"
            height="2.5rem"
          />
          <span className="text-t2 text-[.625rem] text-center">
            {t('footer.tip.download')}
          </span>
        </div>
      </div>
      <div className="w-full h-px bg-[#0B0C0E]" />
      <div className="text-t3 text-xs capitalize">{t('footer.tip.intro1')}</div>
      <div className="text-t3 text-xs">{t('footer.tip.intro2')}</div>
    </div>
  ) : (
    <NoMoreData />
  );
};

export default Footer;
