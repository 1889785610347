import {goTo, toPriceStr} from '@/utils';
import {useBalance, useLogin} from '@/utils/state-hooks';
import {Image} from 'antd-mobile';
import React from 'react';
import {useTranslation} from 'react-i18next';
const balanceIcon = require('@assets/icons/balance.webp');
const Balance = () => {
  const {t} = useTranslation();
  const login = useLogin();
  const amount = useBalance();

  return (
    <div
      className="flex flex-row justify-end items-center gap-2"
      onClick={() => goTo('Recharge')}>
      <div className="flex flex-col items-end">
        <span className="text-t3 text-[0.625rem]">{t('balance')}</span>
        <span className="text-t1 text-xs font-bold">
          {login ? toPriceStr(amount, {suffixUnit: 'K'}) : '-'}
        </span>
      </div>
      <Image src={balanceIcon} className="size-6" />
    </div>
  );
};

export default Balance;
