import React, {useRef} from 'react';
import {FC} from 'react';
import {Image} from 'antd-mobile';
import {useTranslation} from 'react-i18next';
import {useSize} from 'ahooks';
interface CasinoGamesCardProps {
  pic?: string;
  title?: string;
  hasDesc?: boolean;
  desc?: string;
  odds?: string;
  width?: string;
  height?: string;
  className?: string;
  onlyPic?: boolean;
  onClick?: () => void;
}

const CasinoGamesCard: FC<CasinoGamesCardProps> = ({
  pic,
  title,
  hasDesc = true,
  desc = '-',
  odds,
  width = 'auto',
  height,
  className = '',
  onlyPic = false,
  onClick,
}) => {
  const {t} = useTranslation();
  const cardRef = useRef<HTMLDivElement>(null);
  const cardSize = useSize(cardRef);

  return (
    <div
      className={`flex flex-col gap-1 ${className}`}
      style={{width}}
      ref={cardRef}
      onClick={onClick}>
      <Image
        width={width}
        height={height ? height : ((cardSize?.width || 0) / 140) * 105}
        src={pic}
        lazy
        className="rounded-lg"
      />
      {!onlyPic && (
        <>
          <div className="flex flex-col items-center">
            <span className="text-xs overflow-hidden text-ellipsis break-all whitespace-nowrap max-w-full">
              {title}
            </span>
            {hasDesc && (
              <span className="text-[.625rem] text-t3 overflow-hidden text-ellipsis break-all whitespace-nowrap max-w-full">
                {desc}
              </span>
            )}
          </div>

          {!!odds && (
            <div className="relative flex flex-row items-center bg-card rounded-s overflow-hidden p-1">
              <div
                className="absolute z-0 h-full top-0 left-0 bg-c2"
                style={{width: odds}}
              />
              <span className="z-10 text-[.625rem]">
                {t('category.label.odds')}{' '}
                <span className="font-bold">{odds}</span>
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CasinoGamesCard;
