import React from 'react';
import {FC} from 'react';
import Service from './service';
import Top, {useTop} from './top';
import styles from './homeside.module.scss';

interface HomeSideProps {
  hasTop?: boolean;
  onTop?: () => void;
}

const HomeSide: FC<HomeSideProps> = ({hasTop = false, onTop}) => {
  return (
    <div
      className={`flex flex-col gap-3 fixed bottom-[8.25rem] z-20 ${styles.homeside}`}>
      <Service />
      <Top onClick={() => onTop?.()} hasTop={hasTop} />
    </div>
  );
};

export {useTop};

export default HomeSide;
