import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import styles from './homeside.module.scss';
import {ReactComponent as ArrowRoundSvg} from '@assets/svgs/arrow-round.svg';
import {useTranslation} from 'react-i18next';
import {Button} from 'antd-mobile';
import {useThrottleFn} from 'ahooks';

interface TopProps {
  hasTop?: boolean;
  onClick?: () => void;
}

const Top: FC<TopProps> = ({onClick, hasTop}) => {
  const {t} = useTranslation();
  return (
    <Button
      onClick={onClick}
      className={`${styles['homeside-top']} w-[3.25rem] rounded-full ${
        hasTop ? 'h-[3.25rem] p-[.1875rem]' : 'h-0 p-0 opacity-0'
      }`}>
      <div className="size-full rounded-full bg-white flex flex-col items-center justify-center">
        <ArrowRoundSvg
          className={`${styles['arrow-round']} size-4 rotate-180`}
        />
        <span className="text-[#48505D] text-xs font-bold">
          {t('label.topUpp')}
        </span>
      </div>
    </Button>
  );
};

export function useTop<T extends HTMLElement>() {
  const parentRef = useRef<T>(null);
  const [hasTop, setHasTop] = useState(false);
  const {run} = useThrottleFn(
    () => {
      const parent = parentRef.current;

      if (!parent) {
        return;
      }
      if (parent.scrollTop > parent.clientHeight) {
        setHasTop(true);
      } else {
        setHasTop(false);
      }
    },
    {wait: 100},
  );
  const onTop = useCallback((noAnimate = false) => {
    const parent = parentRef.current;
    parent?.scrollTo({top: 0, behavior: noAnimate ? 'auto' : 'smooth'});
  }, []);
  useEffect(() => {
    const parent = parentRef.current;
    const handleScroll = () => {
      run();
    };

    parent?.addEventListener('scroll', handleScroll);
    return () => {
      parent?.removeEventListener('scroll', handleScroll);
    };
  }, [run]);
  return {
    parentRef,
    hasTop,
    onTop,
  };
}

export default Top;
