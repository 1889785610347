import {Button} from 'antd-mobile';
import {ReactComponent as ServiceSvg} from '@assets/svgs/service.svg';
import React from 'react';
import {goCS} from '@/utils';

const Service = () => {
  return (
    <Button
      onClick={() => goCS()}
      className="size-12 bg-c1 rounded-full flex items-center justify-center">
      <ServiceSvg className="size-[1.875rem]" />
    </Button>
  );
};

export default Service;
