import React, {CSSProperties} from 'react';
import {Input, InputProps} from '@rneui/themed';
import {ViewProps, StyleSheet, TextStyle} from 'react-native';
import theme from '@/style';
import globalStore from '@/services/global.state';
import {BasicObject} from '@/types';

export interface TextInputProps {
  hasMax: boolean;
  style?: ViewProps;
  containerStyle?: CSSProperties;
  textInputStyle?: TextStyle;
  onMaxPress?: (value: string) => void;
  rightElement?: React.ReactElement<{}>;
  onValueChange?: (value: string) => void;
}

const TextInput: React.FC<InputProps & TextInputProps> = props => {
  const {
    value = '',
    onValueChange = () => {},
    placeholder,
    rightElement,
    hasMax = false,
    style = {},
    containerStyle = {},
    textInputStyle = {},
    onBlur,
    onFocus,
  } = props;

  return (
    <div
      className="bg-[var(--BG)] px-3 border border-line flex flex-row w-full h-12 items-center rounded-lg"
      style={containerStyle}>
      <span className="text-t3 text-sm font-bold mr-[-0.325rem]">
        {globalStore.currency}
      </span>
      <Input
        containerStyle={[theme.padding.lrm, styles.inputContainer, style]}
        inputContainerStyle={styles.inputContainerStyle}
        keyboardType="numeric"
        // eslint-disable-next-line react-native/no-inline-styles
        style={[styles.inputStyles, {outlineWidth: '0'}, textInputStyle]}
        value={value}
        errorStyle={styles.error}
        onChangeText={v => {
          if (v) {
            if (/^[0-9]+$/.test(v)) {
              onValueChange(v);
            } else {
              onValueChange(v || '');
            }
          } else {
            onValueChange('');
          }
        }}
        rightIcon={hasMax ? rightElement : undefined}
        placeholder={placeholder}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </div>
  );
};

const baseInputStyle = {
  minHeight: 48,
  maxHeight: 48,
  height: 48,
  padding: 0,
  ...theme.font.main,
  ...theme.font.fm,
} as BasicObject;

const inputStyle = globalStore.isWeb
  ? {
      ...baseInputStyle,
      caretColor: theme.fontColor.main,
      outlineWidth: '0',
    }
  : baseInputStyle;

const styles = StyleSheet.create({
  inputStyles: {
    ...inputStyle,
  },
  inputContainer: {
    paddingBottom: 0,
  },
  error: {
    margin: 0,
    height: 0,
  },
  inputContainerStyle: {
    borderBottomWidth: 0,
  },
});

export default TextInput;
