import {LotteryGameCardItem} from '@/common-pages/lottery/lottery.service';
import React from 'react';
import {FC, useMemo} from 'react';
import {colors} from './constant';
import {renderClose} from './mix-lotto-card';
import {Image} from 'antd-mobile';
// import {useTranslation} from 'react-i18next';
import {formatDate, getUrlParams, toPriceStr} from '@/utils';
import styles from './category.module.scss';

interface DigitCardProps {
  data: LotteryGameCardItem;
  index: number;
  onClick?: () => void;
}
const DigitCard: FC<DigitCardProps> = ({data, index, onClick}) => {
  const {otherParams, name, gameUrl} = data;
  // const {t} = useTranslation();

  const otherParamsData = useMemo(() => {
    if (!otherParams) {
      return null;
    }
    try {
      return JSON.parse(otherParams);
    } catch (error) {
      return null;
    }
  }, [otherParams]);
  const urldata = useMemo(() => {
    return getUrlParams(gameUrl);
  }, [gameUrl]);
  console.log('urldata', urldata);
  const linearColor = useMemo(() => {
    return colors[index % colors.length];
  }, [index]);
  const isQuick = useMemo(() => {
    return data.gameType.toLowerCase() === 'quick 3d';
  }, [data]);
  const closed = useMemo(() => {
    return (
      otherParamsData &&
      otherParamsData.stopTime < new Date().getTime() &&
      !isQuick
    );
  }, [isQuick, otherParamsData]);
  return (
    <div
      className="flex flex-col pb-3 pt-1 h-[9.75rem] justify-between rounded-lg relative overflow-hidden"
      style={{
        background:
          otherParamsData && otherParamsData.background
            ? otherParamsData.background
            : `linear-gradient(180deg, ${linearColor[0]} 0%, ${linearColor[1]} 100%)`,
      }}
      onClick={onClick}>
      {closed && renderClose}
      <div className="flex flex-col items-center">
        <div className={'size-[5rem] relative'}>
          <div
            className={`absolute top-0 left-0 ${styles['circle-blur']} size-full`}
          />
          <Image
            width={'5rem'}
            height={'5rem'}
            className="absolute top-0 left-0"
            src={closed ? data.otherUrl || data.gamePic : data.gamePic}
            lazy
          />
        </div>
        <div className="flex flex-col gap-1 items-center">
          <span className="text-white text-base leading-none font-black font-din">
            {toPriceStr(otherParamsData?.wonAmount || 10000, {fixed: 0})}
          </span>
          <span className="text-white text-[.5625rem] font-black font-din leading-none">
            {isQuick ? `${name}-${urldata.cycle}min` : name}
          </span>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <span className="text-white text-[.5625rem] font-bold leading-none">
          {!isQuick ? 'Next Draw' : 'Type'}
        </span>
        <span className="text-white text-[.5625rem] font-bold leading-none">
          {!isQuick
            ? otherParamsData && otherParamsData.stopTime
              ? formatDate(otherParamsData.stopTime, 'Mon ddsuffix HH:mm')
              : '--'
            : urldata.cycle + ' minutes'}
        </span>
      </div>
    </div>
  );
};

export default DigitCard;
