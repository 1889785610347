import {Input, InputProps} from '@rneui/themed';
import {StyleSheet, ActivityIndicator} from 'react-native';
import React from 'react';
import theme from '@/style';
import globalStore from '@/services/global.state';
import {BasicObject, SafeAny} from '@/types';
import {Button} from 'antd-mobile';
import {useTranslation} from 'react-i18next';
import {sendCode} from '@/common-pages/login/login.service';

export interface InputFieldProps {
  required?: boolean;
  label: string;
  tip?: string;
  phoneNumber?: string;
  codeText?: string;
  hasCode?: boolean;
  name?: string;
  status?: 'success' | 'error';
  positionTranslation?: boolean;
}

const InputField: React.FC<InputProps & InputFieldProps> = props => {
  const timerRef = React.useRef<SafeAny>();
  const timeCount = React.useRef(60);
  const [sec, setSec] = React.useState(60);
  const [codeLoading, setCodeLoading] = React.useState(false);
  const [showTime, setShowTime] = React.useState(false);
  const {i18n} = useTranslation();
  const {
    required = false,
    label = '',
    tip,
    hasCode = false,
    phoneNumber = '',
    codeText = i18n.t('login.label.get-otp'),
    value,
    status,
    onChangeText,
    placeholder,
    keyboardType,
    maxLength,
    positionTranslation = false,
  } = props;

  const timeDown = () => {
    setShowTime(true);
    timerRef.current && clearInterval(timerRef.current);
    timerRef.current = setInterval(() => {
      if (timeCount.current === 1) {
        setShowTime(false);
        clearInterval(timerRef.current);
        timeCount.current = 60;
        setSec(60);
      } else {
        timeCount.current -= 1;
        setSec(timeCount.current);
      }
    }, 1000);
  };

  const onSendCode = async () => {
    setCodeLoading(true);
    await sendCode(phoneNumber);
    setCodeLoading(false);
    timeDown();
  };

  return (
    <div
      className={`p-3 rounded-[0.375rem] bg-card border ${
        status === 'error' ? 'border-[red]' : 'border-[var(--T4)]'
      } ${hasCode ? 'flex flex-row justify-between items-center' : ''}`}>
      <div className="flex flex-col gap-3">
        <div className="flex flex-row items-center">
          {positionTranslation && (
            <div className="text-[#FF8733] mr-1 text-xs opacity-0">*</div>
          )}
          {required && <div className="text-[#FF8733] mr-1 text-xs">*</div>}
          <div className="text-sm">{label}</div>
          {tip ? (
            <div className="text-[#848490] ml-2 text-xs">{tip}</div>
          ) : null}
        </div>
        <Input
          containerStyle={styles.containerStyle}
          inputContainerStyle={styles.inputContainerStyle}
          style={[
            styles.input,
            theme.font.fontInterBold,
            // eslint-disable-next-line react-native/no-inline-styles
            {outlineWidth: '0'},
            status === 'error' && {color: theme.basicColor.red},
          ]}
          value={value}
          onChangeText={onChangeText}
          placeholder={placeholder}
          keyboardType={keyboardType}
          errorStyle={styles.error}
          maxLength={maxLength}
        />
      </div>
      {hasCode && (
        <Button
          disabled={showTime || codeLoading}
          onClick={onSendCode}
          className="w-20 h-8 rounded-[0.25rem] border border-[#FF6900] flex items-center justify-center">
          {/* style={[styles.button, showTime && styles.disabled]}> */}
          {codeLoading ? (
            <ActivityIndicator />
          ) : (
            <div
              className={`font-bold text-sm whitespace-nowrap ${
                showTime ? 'text-t4' : 'text-[#FF6900]'
              }`}>
              {showTime ? `${sec}S` : codeText}
            </div>
          )}
        </Button>
      )}
    </div>
  );
};

const baseInputStyle = {
  minHeight: 16,
  maxHeight: 16,
  height: 16,
  padding: 0,
  ...theme.font.main,
  ...theme.font.fm,
} as BasicObject;

const inputStyle = globalStore.isWeb
  ? {
      ...baseInputStyle,
      outlineWidth: '0',
      caretColor: theme.fontColor.main,
    }
  : baseInputStyle;

const styles = StyleSheet.create({
  input: {
    ...inputStyle,
  },
  error: {
    margin: 0,
    height: 0,
  },
  containerStyle: {
    paddingBottom: 0,
  },
  inputContainerStyle: {
    borderBottomWidth: 0,
  },
  button: {
    width: 78,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
    ...theme.background.primary,
    ...theme.borderRadius.xs,
  },
  disabled: {
    backgroundColor: '#DCDCE5',
  },
});

export default InputField;
