'use client';

import {useCallback} from 'react';
import styles from './nav-title.module.scss';
import {goBack} from '@/utils';
import React from 'react';
import {ReactComponent as Back} from '@/assets/svgs/back.svg';
export interface NavTitleProps {
  title: string;
  hideBack?: boolean;
  onBack?: () => void;
  leftNode?: React.ReactNode;
  rightNode?: React.ReactNode;
  onClose?: () => void;
  leftIconColor?: string;
  className?: string;
}

/**
 * Basic Title
 */
const NavTitle = ({
  title,
  hideBack,
  onBack,
  leftNode,
  rightNode,
  className = '',
  // color,
  // background,
  // boxShadow,
  leftIconColor,
}: NavTitleProps) => {
  const handleBack = useCallback(() => {
    if (!onBack && !hideBack) {
      goBack();
    }
  }, [onBack, hideBack]);

  return (
    <div
      className={`w-full h-12 flex justify-between items-center relative z-50 px-3 ${styles['nav-title']} ${className}`}>
      {/* gap未确认，先暂时不写，之后确认再补上 */}
      <div className="flex flex-row items-center z-10">
        {!hideBack && (
          <div onClick={handleBack}>
            <Back color={leftIconColor || 'var(--T1)'} />
          </div>
        )}
        {leftNode}
      </div>
      <div className="flex flex-row items-center z-10">{rightNode}</div>
      <div
        className={`absolute top-0 left-0 w-full h-full flex text-t1 text-[1.125rem] items-center px-3 nav-title-text ${
          hideBack && !leftNode ? 'justify-start' : 'justify-center'
        } font-bold`}>
        <span className="max-w-[12.5rem] text-ellipsis break-all whitespace-nowrap">
          {title}
        </span>
      </div>
    </div>
  );
};

export default NavTitle;
