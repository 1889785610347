'use client';

import React, {useEffect, useState} from 'react';
import {Mask, SpinLoading} from 'antd-mobile';

export interface ISpinProps {
  loading: boolean;
  children: React.ReactNode;
}

/**
 * Basic Title
 */
const Spin = ({loading, children}: ISpinProps) => {
  const [endDealy, setEndDealy] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setEndDealy(true);
    }, 200);
    return () => clearTimeout(timer);
  }, [endDealy]);
  return (
    <>
      {children}
      <Mask
        visible={endDealy && loading}
        className="flex items-center justify-center">
        <SpinLoading style={{'--size': '3rem'}} />
      </Mask>
    </>
  );
};

export default Spin;
