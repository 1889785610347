export const colors: [string, string][] = [
  ['#2ABBFB', '#0095D6'],
  ['#B9A0FF', '#6F3CFF'],
  ['#FF7050', '#E53C18'],
  ['#FFC738', '#FF8A00'],
  ['#FFAF50', '#FF6B00'],
  ['#FF7A2E', '#FF3D00'],
  ['#A35B33', '#6A3C20'],
  ['#3ABD47', '#0D8719'],
  ['#FF97C9', '#D61471'],
  ['#448BF4', '#273FBA'],
  ['#BB5EF1', '#8122B8'],
  ['#28B299', '#0D7764'],
  ['#46597D', '#323552'],
  ['#629B29', '#3A730C'],
  ['#CD9A4C', '#BA713C'],
  ['#175EA0', '#2A27BA'],
  ['#D95169', '#B82273'],
  ['#2DAE70', '#13695A'],
];

export const baseClassMap: Record<string, string> = {
  '1': 'bg-mix-lottery-sort-1',
  '2': 'bg-mix-lottery-sort-2',
  '3': 'bg-mix-lottery-sort-3',
  '4': 'bg-mix-lottery-sort-4',
  '5': 'bg-mix-lottery-sort-5',
  '6': 'bg-mix-lottery-sort-6',
  '7': 'bg-mix-lottery-sort-7',
  '8': 'bg-mix-lottery-sort-8',
  '9': 'bg-mix-lottery-sort-9',
  '1101': 'bg-mix-lottery-sort-10',
  '1102': 'bg-mix-lottery-sort-11',
  '1103': 'bg-mix-lottery-sort-12',
};

export function getBaseClass(id: string) {
  if (baseClassMap[id]) {
    return baseClassMap[id];
  }

  if (!Number.isNaN(Number(id))) {
    return `bg-mix-lottery-sort-${(Number(id) % 13) + 1}`;
  }

  return baseClassMap['1'];
}
